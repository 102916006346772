/* App.css */
.App {
  text-align: center;
}

header {
  padding: 20px;
  background-color: #333;
  color: white;
}

nav a {
  margin: 0 10px;
  color: white;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}

.featured-products {
  padding: 20px;
}

footer {
  padding: 10px;
  background-color: #333;
  color: white;
}

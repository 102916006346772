/* ProductDetailsPage.css */

.product-page {
    text-align: center;
    padding: 20px;
    background-color: #FFFFFF; /* Dark background */
    color: #111; /* Light text color */
  }
  
  .product-page h1 {
    margin-bottom: 20px;
    background-color: #333; /* Light blue background */
    color: white;  /*White text color */
    padding: 10px; /* Add padding to create space around the title */
    border-radius: 5px; /* Add border radius for a rounded appearance */
    width: 30%; /* Adjust the width of the heading */
    margin: 0 auto; /* Center align the heading */
  }
  
  .product-details-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .product-details {
    background-color: ash; /* White background */
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 20px;
  }
  
  .product-details h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .product-details p {
    font-size: 16px;
    margin-bottom: 15px;
  }
  
  .image-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Dynamic grid layout */
    grid-gap: 10px;
    justify-items: center;
  }
  
  .image-gallery img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .contact-link {
    display: block;
    margin-bottom: 20px; /* Add margin below the link */
    color: white; /* Blue link color */
    text-decoration: none;
  }
  
  .contact-link:hover {
    text-decoration: underline;
  }

  .product-details img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s ease; /* Add transition for smooth effect */
  }
  
  .product-details img:hover {
    transform: scale(1.05); /* Increase size on hover */
  }
/* DashBoardLoginForm.css */

/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Container styling */
.login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f2f5;
  font-family: 'Arial', sans-serif;
}

/* Header styling */
.login-header {
  font-size: 2rem;
  margin-bottom: 30px;
  color: #333;
}

/* Form styling */
.login-form {
  background-color: #ffffff;
  padding: 40px 50px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  width: 100%;
  max-width: 450px;
}

/* Form group styling */
.form-group {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
}

/* Label styling */
.form-label {
  margin-bottom: 10px;
  font-size: 1.1rem;
  color: #555;
}

/* Input field styling */
.form-input {
  padding: 12px 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Input field focus styling */
.form-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

/* Login button styling with higher specificity */
.login-form-container .login-form .login-button {
  width: 100%;
  padding: 14px;
  background-color: #28a745; /* Green for visibility */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  opacity: 1; /* Ensure full opacity */
  /* Add a solid border to prevent blending */
  border: 2px solid #28a745;
}

/* Login button hover state */
.login-form-container .login-form .login-button:hover {
  background-color: #218838;
  border-color: #218838;
}

/* Login button active state */
.login-form-container .login-form .login-button:active {
  background-color: #1e7e34;
  border-color: #1e7e34;
  transform: scale(0.98);
}

/* Link styling */
.login-form-container a {
  display: block;
  text-align: center;
  margin-top: 25px;
  font-size: 1rem;
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.login-form-container a:hover {
  color: #0056b3;
}

/* EditProductForm.css */

.edit-product-form {
  max-width: 90%;
  width: 500px; /* Adjust width as needed */
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

.edit-product-form h3 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.edit-product-form label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #555;
}

.edit-product-form input[type="text"],
.edit-product-form textarea {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.edit-product-form textarea {
  height: 100px;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.edit-product-form button[type="submit"],
.edit-product-form button[type="button"] {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-product-form button[type="submit"] {
  background-color: #007bff;
  color: #fff;
}

.edit-product-form button[type="submit"]:hover {
  background-color: #0056b3;
}

.edit-product-form button[type="button"] {
  background-color: #dc3545;
  color: #fff;
}

.edit-product-form button[type="button"]:hover {
  background-color: #c82333;
}

.additional-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.additional-image,
.existing-additional-image {
  position: relative;
  box-sizing: border-box;
  flex: 1 1 calc(33.33% - 10px); /* Adjust to fit three images per row */
  max-width: calc(33.33% - 10px);
  overflow: hidden;
}

.additional-image img,
.existing-additional-image img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  display: block;
}

.additional-image button,
.existing-additional-image button {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.additional-image button:hover,
.existing-additional-image button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

@media (max-width: 768px) {
  .additional-image,
  .existing-additional-image {
    flex: 1 1 calc(50% - 10px);
  }
}

@media (max-width: 480px) {
  .additional-image,
  .existing-additional-image {
    flex: 1 1 100%;
  }
}

/* ProductPage.css */

.product-page {
  text-align: center;
  padding: 20px;
  background-color: white; /* Dark background */
  color: #f2f2f2; /* Light text color */
}

.product-page h1 {
  margin-bottom: 20px;
  background-color: #333; /* Light blue background */
  color: #111; /* White text color */
  padding: 10px; /* Add padding to create space around the title */
  border-radius: 1px; /* Add border radius for a rounded appearance */
  width: 30%; /* Adjust the width of the heading */
  margin: 0 auto; /* Center align the heading */
}

.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-gap: 10px;
  justify-items: center;
}

.product {
  position: relative; /* Position relative for absolute positioning of links */
  padding: 10px;
  background-color: #fff; /* White background */
  border-radius: 1px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product:hover {
  transform: scale(1.05); /* Increase size on hover */
}

.product h2 {
  margin-bottom: 5px;
  color: #333; /* Dark gray text color */
}

.product p {
  color: #555; /* Dark gray text color */
}

.product img {
  max-width: 100%;
  height: auto;
  border-radius: 3px;
}

.home-link {
  display: block;
  margin-bottom: 20px; /* Add margin below the link */
  color:#111; /* Blue link color */
  text-decoration: none;
}

.home-link:hover {
  text-decoration: underline;
}

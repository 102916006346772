/* Container for the entire product management section */
.product-management {
    padding: 50px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
    background-color: #f4f6f9; /* Slightly off-white background */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header for the management section */
.product-management h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #333;
}

/* Styling for the add product form */
.add-product-form {
    max-width: 600px;
    margin: 30px auto;
    padding: 40px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Header within the form */
.add-product-form h3 {
    margin-bottom: 30px;
    font-size: 1.8rem;
    color: #007bff;
}

/* Label styling */
.add-product-form label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: #555;
    font-size: 1.2rem; /* Increase font size for labels */
}

/* Input and textarea styling */
.add-product-form input[type="text"],
.add-product-form textarea {
    width: 100%;
    padding: 15px;
    margin-bottom: 30px; /* Increase margin below input fields */
    border: 1px solid #ccc;
    border-radius: 6px;
    box-sizing: border-box;
    font-size: 1.2rem; /* Increase font size of input fields */
}

.add-product-form textarea {
    height: 150px;
    resize: none;
}

/* Button styling */
.add-product-form button {
    width: 100%;
    padding: 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 30px; /* Increase margin above buttons */
}

.add-product-form button:hover {
    background-color: #0056b3;
}

/* Product list styling */
.product-management h3 {
    font-size: 2rem;
    color: #007bff;
    margin: 40px 0 30px;
    text-align: center;
}

.product-management ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.product-management ul li {
    padding: 25px;
    margin: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    flex: 1 1 45%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.product-management ul li:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Button styles within the list items */
.product-management .delete-button,
.product-management .edit-button {
    padding: 12px 20px;
    margin: 15px 10px 0 0;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.delete-button {
    background-color: #dc3545;
    color: white;
}

.delete-button:hover {
    background-color: #c82333;
}

.edit-button {
    background-color: #007bff;
    color: white;
}

.edit-button:hover {
    background-color: #0056b3;
}

/* Styling for product category text */
.product-management ul li p {
    margin: 15px 0 0;
    font-size: 1rem;
    color: #555;
    font-weight: bold;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .product-management ul li {
        flex: 1 1 100%;
    }

    .add-product-form {
        max-width: 100%;
        margin: 30px 10px;
    }
}

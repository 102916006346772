/* General Styles */
.home-page {
  font-family: 'Roboto', sans-serif;
  color: #f0f0f0;
  background-color: #1a202c; /* Deep blue background color */
  padding: 20px;
}

h1, h2, h3 {
  color: #edf2f7; /* Light text color for headings */
}

a {
  text-decoration: none;
}

/* Header Styles */
header {
  background-color: #2d3748;
  color: #edf2f7;
  padding: 20px 0;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

nav {
  margin-top: 20px;
}

nav a {
  color: #cbd5e0;
  margin: 0 15px;
  font-size: 16px;
  position: relative;
}

nav a:hover {
  color: #edf2f7;
}

nav a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background-color: #63b3ed;
  left: 50%;
  bottom: -5px;
  transition: width 0.3s ease, left 0.3s ease;
}

nav a:hover::after {
  width: 100%;
  left: 0;
}

/* Hero Section Styles */
.hero-section {
  height: 450px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-bottom: 40px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
}

.hero-content {
  text-align: center;
  color: #edf2f7;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1.5s ease-out forwards;
}

.hero-content h1 {
  font-size: 48px;
  margin-bottom: 20px;
  letter-spacing: 1.5px;
}

.cta-button {
  display: inline-block;
  padding: 12px 30px;
  background-color: #3182ce;
  color: #edf2f7;
  border-radius: 38px;
  font-size: 18px;
  transition: background-color 0.3s, transform 0.3s;
  animation: buttonFadeIn 2s ease-out forwards;
}

.cta-button:hover {
  background-color: #63b3ed;
  transform: scale(1.05);
}

/* Company Name Styling */
.company-name {
  font-family: 'Playfair Display', serif;
  font-size: 48px; /* Adjust size as needed */
  font-weight: 700; /* Bold */
  color: #ffb347; /* A warm, eye-catching color */
  text-transform: uppercase; /* Optional: Make all letters uppercase */
  letter-spacing: 2px; /* Optional: Add some space between letters */
  text-align: center; /* Center the text */
  margin-bottom: 20px; /* Space below the name */
}


/* Section Styles */
section {
  padding: 40px 20px;
  background-color: #2d3748;
  border-radius: 10px;
  margin-bottom: 40px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  animation: sectionFadeIn 1.5s ease-out forwards;
}

section h2 {
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
  color: #63b3ed;
}

section p {
  font-size: 18px;
  line-height: 1.7;
  color: #e2e8f0;
  margin-bottom: 30px;
  text-align: center;
}

.about-section {
  background-color: #1a202c;
}

/* Repair Services Section */
.repair-services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.repair-service-item {
  flex: 1 1 100%; /* Full width on small screens */
  max-width: 300px;
  background-color: #4a5568;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s;
  animation: itemFadeIn 1.5s ease-out forwards;
}

.repair-service-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.repair-service-item h3 {
  font-size: 22px;
  margin-bottom: 10px;
  color: #e2e8f0;
}

.repair-service-item p {
  font-size: 16px;
  color: #cbd5e0;
}

/* Media Query for Larger Screens */
@media (min-width: 768px) {
  .repair-service-item {
    flex: 1 1 30%; /* 3 items per row on larger screens */
    max-width: none;
  }
}

.repair-services-images {
  display: flex;
  justify-content: center; /* Center the image horizontally */
  margin-bottom: 20px; /* Add some space below the image */
}

.repair-services-images img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Media Query for Larger Screens */
@media (min-width: 768px) {
  .repair-services-images img {
    max-width: 60%; /* Adjust the width of the image on larger screens if needed */
  }
}

/* Product Showcase Section */
.product-showcase {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  justify-items: center;
}

.product-showcase img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s;
  animation: imgFadeIn 2s ease-out forwards;
}

.product-showcase img:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

/* Contact Section */
.contact-section ul {
  list-style: none;
  padding: 0;
  text-align: center;
}

.contact-section li {
  margin-bottom: 15px;
  font-size: 18px;
  color: #cbd5e0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-section img {
  width: 20px; /* Adjusted size of the social media logos */
  height: auto;
  vertical-align: middle;
  margin-right: 8px; /* Space between logo and text */
}

.contact-section a {
  color: #63b3ed;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-section a:hover {
  color: #edf2f7;
}


/* Footer Styles */
footer {
  background-color: #2d3748;
  color: #edf2f7;
  padding: 20px 0;
  text-align: center;
}

footer p {
  font-size: 14px;
  margin: 0;
}

footer nav a {
  color: #cbd5e0;
  margin: 0 15px;
  font-size: 16px;
  transition: color 0.3s ease;
}

footer nav a:hover {
  color: #63b3ed;
}

/* Smooth Scrolling */
html {
  scroll-behavior: smooth;
}

/* Keyframe Animations */
@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes buttonFadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes sectionFadeIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes itemFadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes imgFadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}



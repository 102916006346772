/* Container for the form */
.add-product-form {
  max-width: 500px;
  margin: 40px auto;
  padding: 40px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Centered and slightly larger header */
.add-product-form h3 {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 30px;
  color: #333;
}

/* Label styling with increased font size */
.add-product-form label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 1.1rem;
  color: #555;
}

/* Input field styling */
.add-product-form input[type="file"],
.add-product-form input[type="text"],
.add-product-form select,
.add-product-form textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Input focus styling */
.add-product-form input[type="text"]:focus,
.add-product-form select:focus,
.add-product-form textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
  outline: none;
}

/* File input specific styling */
.add-product-form input[type="file"] {
  padding: 10px;
  background-color: #f8f9fa;
  cursor: pointer;
}

/* Textarea for description */
.add-product-form textarea {
  height: 120px;
  resize: none;
}

/* Submit button styling */
.add-product-form button[type="submit"] {
  width: 100%;
  padding: 14px;
  background-color: #007bff;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  border: 1px solid #007bff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Hover effect for the submit button */
.add-product-form button[type="submit"]:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .add-product-form {
    padding: 20px;
  }

  .add-product-form input[type="file"],
  .add-product-form input[type="text"],
  .add-product-form select,
  .add-product-form textarea {
    padding: 10px;
  }

  .add-product-form button[type="submit"] {
    padding: 12px;
  }
}
